/* Default styles for larger screens */
#top-footer {
    margin: 28vh auto 5vh auto;
  }
  
  #top-footer h3, #bottom-footer h3 {
    margin-top: -15px;
  }
  
  #bottom-footer {
    margin-top: 6vh;
    margin-bottom: 20vh;
  }
  
  a {
    font-weight: 600;
    font-size: 1em;
    color: #996e66;
    text-decoration: none;
    margin-top: -30px;
  }
  
  #with-icon {
    display: flex;
  }
  
  #icon {
    margin: -15px auto auto 10px;
    font-size: 1em;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 600px) {
    #top-footer {
      margin: 17vh auto auto auto ;
    }
  
    #top-footer h3, #bottom-footer h3 {
      margin-top: auto;
      font-size: 1.5rem;
      line-height: 0;
    }
  
    #bottom-footer {
      margin-bottom: 4vh;
    }
  
    a {
        font-size: 1em;
        line-height: 0;
        margin-top: 22px; /* Adjusted line height for mobile */
      }
  
    #icon {
      margin: -10px auto auto 8px;
      font-size: 0.9em;
    }
  }
  