.link-button {
  color: #939723;
  font-size: 7.4vw;
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 400;
  margin: auto;
  letter-spacing: -5px;
  border: none;
  background-color: transparent;
  text-decoration: none; /* Removes the underline from links */
  position: relative; /* Added to position the underline */
}

.link-button:hover {
  color: #A68881;
}

.link-button.selected {
 color: #996e66; 
}

.hidden {
  display: block;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

#kind {
  position: fixed;
  top: -2vh;
  left: 19.5vw;
  z-index: 1000;
  transform: translateX(-37%);
  transform: scale(1.35, 1);
}

#contact {
  position: fixed;
  top: 38vh;
  left: -12.5vw;
  transform: rotate(-90deg) scale(1.35, 1);
}

#works {
  position: fixed;
  top: 27vh;
  right: -9vw;
  transform: rotate(90deg) scale(1.35, 1);
}


@media screen and (max-width: 768px) {
  /* Adjusted styles for screens up to 768px width (commonly used for tablets and mobile devices) */
  .link-button {
    font-size: 3.5vw;
    letter-spacing: -2px;
  }

#kind, #contact, #works {
  font-size: 2em;
}

  #kind {
    top: 4vh;
    left: 19vw;
    z-index: 1000;
  }

  #contact {
    top: 15vh;
    left: -13vw;
    z-index: 1000;
  }

  #works {
    top:12vh;
    left: 83%;
    z-index: 1000;
  }
}
