/* Work.css */

.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
}

.grid-item {
  width: 80%; /* Adjust width as necessary */
  margin: 2vh 0; /* This will add vertical spacing between items */
  text-align: center; /* Center text within the item */
}

.centered-image {
  max-width: 100%; /* Make sure image does not overflow the grid item */
  height: auto; /* Maintain aspect ratio */
  margin-top: 2vh; /* If you want some space above the image */
}

.works-text {
  margin: 5vh 1vh;
  width: 80%;
  text-align: center;
  font-size: 2.5em;
  line-height: 1.25;
}

.left {
  text-align: left;
}

@media screen and (max-width: 768px) {
  /* CSS adjustments for screens up to 768px wide (e.g., tablets and mobile devices) */
  .works-text {
    font-size: 1.6em;
    margin: -10vh auto 5vh;
    width: 70%;
  }
  #project-description {
    width: 100%;
    margin-bottom: 5vh;
  }

  .centered-image {
    margin-top: 0vh;
  }
}
