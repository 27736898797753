/* Default styles for larger screens */
.centered-text {
    margin: 25vh auto;
    width: 80%;
    text-align: center;
    font-size: 2.5em;
    line-height: 1.25;
  }
  
  .contact-form {
    max-width: 60%;
    margin: -10vh auto;
    padding: 20px;
    font-family: 'Cormorant', serif;
  }
  
  .contact-form h2 {
    text-align: center;
    color: #996e66;
    margin-bottom: 20px;
    font-size: 2.5em;
    letter-spacing: .0em;

  }
  
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    box-sizing: border-box; 
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    border: 2px solid #d3d3d3;
    border-radius: 5px;
    font-family: 'Cormorant', serif;
    font-size: 1.25em;
  }
  
  .contact-form textarea {
    height: 100px;
  }
  
  .contact-form button {
    font-weight: 600;
    background-color: #a68881;
    color: white;
    font-size: 1.25em;
    cursor: pointer;

  }

  .contact-form button:hover {
    background-color:#996e66; 
  }
  
  .submission-message {
    color: #939723;
    font-size: 1em;
    font-family: "Dela Gothic One", sans-serif;
    font-weight: 400;
    margin-top: 5%;
    text-align: center;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 600px) {
    .centered-text {
      font-size: 1.6em;
      margin: 10vh auto 15vh;
      width: 70%;

    }
  
    .contact-form {
      padding: 10px;
      max-width: 80%;
    }
  
    .contact-form h2 {
      font-size: 1.2em;
      margin-bottom: 15px;
      letter-spacing: .01em;
      
    }
  
    .contact-form input,
    .contact-form textarea,
    .contact-form button {
      padding: 10px;
      font-size: 1em;
    }
  
    .contact-form textarea {
      height: 80px;
    }

    .contact-form button {
        padding: 10px;
  }}

  .submission-message {
    width: 70%;
    margin: 10vh auto auto auto ;
  }
  
