.main-txt {
  display: flex;
  font-family: "Cormorant", serif;
  font-size: 2em;
  line-height: 65px;
}

#main-txt-left {
  margin: 7% 0% 0% 10%;
  width: 50%;
}

#main-txt-right {
  width: 50%;
  margin: 28% 10% 0% 0%;
  text-align: right;
}

img {
  width: 100%;
}

.left-content,
.right-content {
  width: 80%;
  margin: auto;
}

.right-content {
  text-align: right;
}

h1 {
  font-family: "Cormorant", serif;
  font-size: 1.65em;
  font-weight: 400;
}

h2 {
  font-size: 3.5vw;
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 400;
  margin: auto;
  letter-spacing: -3px;
}

h3 {
  display: flex;
  font-family: "Cormorant", serif;
  font-size: 2.25em;
  font-weight: 500;
  line-height: 42px;
  margin-bottom: 50px;
  margin-top: 8px;
}

.reveal {
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.reveal.from-left {
  transform: translateX(-100%);
}

.reveal.from-right {
  transform: translateX(100%);
}

.reveal.active {
  opacity: 1;
  transform: translateX(0);
}

@media screen and (max-width: 768px) {
    /* CSS adjustments for screens up to 768px wide (e.g., tablets and mobile devices) */
    .main-txt {
      display: flex;
      flex-direction: column;
      font-family: "Cormorant", serif;
      font-size: 1.2em;
      line-height: 1.5;
      width: 75%;
      margin: 14% auto auto auto;
    }
  
    #main-txt-left,
    #main-txt-right {
      width: 100%;
      margin: 2% 0;
      text-align: center;
    }
  
    .left-content,
    .right-content {
      width: 100%;
      margin: 10% auto;
      text-align: center;
    }
    
h1 {
    font-size: 1.6em;
}

    h1,
    h2,
    h3 {
      line-height: 1.5;
      margin-bottom: 20px;
    }

    h2 {
        letter-spacing: .0em;
        font-size: 1.3em;
    }

    h3 {
        width: 80%;
        margin: 10px auto;
        text-align: left;
        font-size: 1.35em;
    }
  
    img {
      max-width: 100%;
    }
  }
  